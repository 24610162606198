import * as React from "react";
import {
    AppBar,
    Box,
    Stack,
    Toolbar,
    Icon,
    CssBaseline,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    ListItemText,
    Button,
    Typography,
} from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
import { useNavigate } from "react-router";
import { CENTER } from "../../utils/styles";
import { Icons } from "../../assets/icons";
import { Hrefs, Services } from "./data";

const NavigationBar = () => {
    const navigate = useNavigate();
    const [isDrawer, setDrawer] = React.useState(false);
    const [profile, setProfile] = React.useState<any>(null);

    const toggleDrawer = () => {
        setDrawer(!isDrawer)
    };

    const profileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setProfile(event.currentTarget);
    };

    const profileClose = () => {
        setProfile(null);
    };

    const Navs = (e: any, item: any) => {
        if (item.label === 'Layanan') {
            profileClick(e)
        } else {
            navigate(item.navigate)
        }
    }

    return (
        <Box sx={{ display: 'flex', width: '100%', position: 'relative' }}>
            <CssBaseline />
            <AppBar
                elevation={0}
                position="fixed"
                sx={{
                    backgroundColor: '#ffffff',
                    width: '100%',
                    top: 0
                }}
            >
                <Toolbar sx={{ ...CENTER }}>
                    {
                        isMobile === true && isTablet === false ?
                            <Stack spacing={2} width={'100%'}>
                                <Stack
                                    width={'100%'}
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                >
                                    <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 40, width: 'auto', objectFit: 'contain', cursor: 'pointer' }} alt="" className="" />
                                    <IconButton onClick={() => setDrawer(true)}>
                                        <Icon fontSize={'medium'} sx={{ color: '#000' }}>menu</Icon>
                                    </IconButton>
                                </Stack>
                            </Stack>
                            :
                            <div style={{ width: '100%', ...CENTER }}>
                                <Stack
                                    width={isTablet ? '95%' : '80%'}
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{ padding: isTablet ? '10px 0' : '20px 0' }}
                                >
                                    <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 'auto', width: isTablet ? 40 : 50, objectFit: 'contain' }} alt="" className="" />
                                    <Stack direction={'row'} gap={isTablet ? 0 : 3} alignItems={'center'} sx={{ color: '#000' }}>
                                        {
                                            Hrefs.map((item, index) => (
                                                <Button key={index} variant="text" color="inherit" onClick={(e) => Navs(e, item)} sx={{ fontSize: isTablet ? 10 : undefined }}>{item.label}</Button>
                                            ))
                                        }
                                        <Menu
                                            anchorEl={profile}
                                            open={Boolean(profile)}
                                            onClose={profileClose}
                                        >
                                            {
                                                Services.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        onClick={() => navigate(item.navigate)}
                                                        sx={{
                                                            "&:hover": {
                                                                color: '#000',
                                                                transition: "all .3s",
                                                                backgroundColor: '#e7e7e7',
                                                            },
                                                            paddingTop: '10px',
                                                            paddingBottom: '10px',
                                                            transition: "all .3s"
                                                        }}
                                                    >
                                                        <ListItemText sx={{ color: "inherit" }}>{item.label}</ListItemText>
                                                    </MenuItem>
                                                ))
                                            }
                                        </Menu>
                                    </Stack>
                                </Stack>
                            </div>
                    }
                </Toolbar>
            </AppBar>
            {
                isMobile ?
                    <Drawer
                        sx={{
                            width: '100%',
                            flexShrink: 0,
                            "& .MuiDrawer-paper": {
                                width: '100%',
                                boxSizing: "border-box",
                            },
                        }}
                        variant={"temporary"}
                        anchor="right"
                        open={isDrawer}
                        onClose={toggleDrawer}
                    >
                        <Stack direction={'column'} gap={3} padding={2}>
                            <Stack direction={'row'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                                <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 40, width: 'auto', objectFit: 'contain' }} alt="" className="" />
                                <IconButton onClick={() => setDrawer(false)}>
                                    <Icon fontSize={'large'} sx={{ color: '#000' }}>close</Icon>
                                </IconButton>
                            </Stack>
                            <Stack direction={'column'} gap={1} sx={{ color: '#000' }}>
                                {
                                    Hrefs.map((item, index) => (
                                        <Stack key={index} direction={'column'} gap={1}>
                                            <Button variant="text" color="inherit" onClick={(e) => Navs(e, item)}>
                                                <Stack direction={'row'} gap={1} width={'100%'} justifyContent={'flex-start'} alignItems={'center'} paddingLeft={2}>
                                                    <Typography variant={'button'} color="#000">{item.label}</Typography>
                                                </Stack>
                                            </Button>
                                            {
                                                item.label === 'Layanan' ?
                                                    Services.map((item, index) => (
                                                        <Button variant="text" color="inherit" key={index} onClick={(e) => Navs(e, item)}>
                                                            <Stack direction={'row'} gap={1} width={'100%'} justifyContent={'flex-start'} alignItems={'center'} paddingLeft={5}>
                                                                <Typography variant={'button'} color="#000">{item.label}</Typography>
                                                            </Stack>
                                                        </Button>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </Stack>
                                    ))
                                }
                            </Stack>
                        </Stack>
                    </Drawer>
                    :
                    null
            }
        </Box >
    );
};

export default NavigationBar;
