import { Button, Grid, Icon, Stack, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import { HomeImg } from '../../assets/images';
import Footer from '../../components/navigation/footer';
import NavigationBar from '../../components/navigation/navigationBar';
import { Colors } from '../../utils/colors';
import { BackgroundImg, BoxShadow, CENTER } from '../../utils/styles';
import { WebPricings } from './data';
import Whatsapp from '../../components/navigation/whatsapp';

const ServicesWebDevelompentPage = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fcfcfd' }}>
            <NavigationBar />
            <Toolbar />
            <Toolbar />
            <Stack direction={'column'} gap={10} width={'100%'} sx={{ ...BackgroundImg }}>
                <div style={{ width: '100%', ...CENTER }}>
                    <Stack direction="row" gap={7} width={isMobile || isTablet ? '90%' : '80%'} alignItems={'center'} justifyContent={'space-between'}>
                        <div style={{ width: isMobile || isTablet ? '100%' : '70%', backgroundColor: '#fff', boxShadow: '-20px 20px 0px 1px rgba(0,0,0,0.20)', borderRadius: 15 }}>
                            <Stack direction={"column"} gap={4} padding={4}>
                                <Typography fontWeight={'700'} variant={isMobile || isTablet ? 'h4' : "h3"} color="#000">Mengapa Anda Harus Memiliki Website?</Typography>
                                <Typography variant={isMobile || isTablet ? 'body1' : "h6"} lineHeight={2} textAlign={'justify'} color="#000">Website adalah "Gerbang Digital" yang menghubungkan bisnis dengan pelanggan dan menciptakan transaksi. Pembuatan website bersama Arenvy mudah dan cepat karena ditangani oleh programmer kompeten dalam bidang web development, dengan keunggulan dan banyak benefit yang akan ada dapat.</Typography>
                            </Stack>
                        </div>
                        {
                            isMobile || isTablet ? null :
                                <img src={HomeImg.homeVector1} style={{ width: '30%', objectFit: 'contain', height: '100%' }} alt="" />
                        }
                    </Stack>
                </div>
                <div></div>
                <div style={{ width: '100%', ...CENTER }}>
                    <Stack direction={'column'} gap={5} width={isMobile || isTablet ? '90%' : '80%'}>
                        <Typography variant={isTablet ? 'h4' : isMobile ? 'h5' : "h3"} color="#000" sx={{ width: '100%', textAlign: isMobile ? 'center' : 'right' }} fontWeight={'600'}>Paket Web Development</Typography>
                        <Grid container spacing={isMobile || isTablet ? 1 : 3}>
                            {
                                WebPricings.map((item, index) => (
                                    <Grid item lg={4} md={6} sm={6} xs={isTablet ? 6 : 12} key={index}>
                                        <div style={{ width: '100%', ...BoxShadow, borderRadius: 10, backgroundColor: '#fff' }}>
                                            <Stack direction={'column'} gap={3} alignItems={'center'} padding={isMobile || isTablet ? 2 : 3}>
                                                <img src={item.image} style={{ width: '100%', aspectRatio: 1, objectFit: 'cover', borderRadius: 20 }} alt="" />
                                                <Link to="https://api.whatsapp.com/send/?phone=6281317284592&text=Halo%20Pak%20Alex,%20saya%20ingin%20bertanya%20tentang%20layanan%20arenvy..." target="_blank">
                                                <Button color={"warning"} sx={{ backgroundColor: Colors.primary, borderRadius: 30 }} variant={'contained'}>Pesan Sekarang</Button></Link>
                                                <Stack direction={'column'} gap={2} width={'100%'}>
                                                    {
                                                        item.services.map((item, index) => (
                                                            <Stack direction={'row'} gap={1} alignItems={'center'} key={index}>
                                                                <Icon fontSize={isMobile || isTablet ? 'small' : 'medium'} sx={{ color: Colors.primary }}>check</Icon>
                                                                <Typography variant={isMobile || isTablet ? 'caption' : "body1"} color="#000">{item}</Typography>
                                                            </Stack>
                                                        ))
                                                    }
                                                </Stack>
                                            </Stack>
                                        </div>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Stack>
                </div>
                <Toolbar />
            </Stack>
            <Footer />
            <Whatsapp/>
        </div>
    )
}

export default ServicesWebDevelompentPage;