export const Images = {
  background: require("./background.png"),
  header: require("./header-bg.png"),
  googleBtn: require("./google-partner.png"),
};

export const HomeImg = {
  homeVector1: require("./home-vector-1.png"),
  homeVector2: require("./home-vector-2.png"),
  homeVector3: require("./home-vector-3.png"),
  homeVector4: require("./home-vector-4.png"),
  homeVector5: require("./home-vector-5.png"),
  homeVector6: require("./home-vector-6.png"),
};

export const ProfileImg = {
  profileImg1: require("./profile-1.png"),
  profileImg2: require("./profile-2.png"),
  profileImg3: require("./profile-3.png"),
  profileSection1: require("./profile-section-1.png"),
  profileSection2: require("./profile-section-2.png"),
  profileVector1: require("./profile-vector-1.png"),
  profileVector2: require("./profile-vector-2.png"),
  profileVector3: require("./profile-vector-3.png"),
};

export const ProjectImg = {
  projectImg1: require("./project-1.png"),
  projectImg2: require("./project-2.png"),
  projectImg3: require("./project-3.png"),
  projectImg4: require("./project-4.png"),
  projectImg5: require("./project-5.png"),
  projectImg6: require("./project-6.png"),
  projectImg7: require("./project-7.png"),
  projectImg8: require("./project-8.png"),
  projectImg9: require("./project-9.png"),
  projectImg10: require("./project-10.png"),
};

export const ServiceImg = {
  serviceGoogle1: require("./service-google-1.png"),
  serviceGoogle2: require("./service-google-2.png"),
  serviceGoogle3: require("./service-google-3.png"),
  serviceGoogle4: require("./service-google-4.png"),
  serviceMeta1: require("./service-meta-1.jpeg"),
  serviceMeta2: require("./service-meta-2.jpeg"),
  serviceMeta3: require("./service-meta-3.jpeg"),
  serviceMeta4: require("./service-meta-4.jpeg"),
  serviceMobile1: require("./service-mobile-1.png"),
  serviceMobile2: require("./service-mobile-2.png"),
  serviceMobile3: require("./service-mobile-3.png"),
  serviceSocial1: require("./service-social-1.png"),
  serviceSocial2: require("./service-social-2.png"),
  serviceSocial3: require("./service-social-3.png"),
  serviceSocial4: require("./service-social-4.png"),
  serviceSocial5: require("./service-social-5.png"),
  serviceSocial6: require("./service-social-6.png"),
  serviceWeb1: require("./service-web-1.png"),
  serviceWeb2: require("./service-web-2.png"),
  serviceWeb3: require("./service-web-3.png"),
  serviceWeb4: require("./service-web-4.png"),
  serviceWeb5: require("./service-web-5.png"),
  serviceWeb6: require("./service-web-6.png"),
};
