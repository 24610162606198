import { ServiceImg } from "../../assets/images";

export const WebPricings = [
  
  {
    image: ServiceImg.serviceWeb4,
    services: [
      "Gratis domain .xyz 1 tahun pertama",
      "Gratis desain halaman website",
      "Gratis tombol whatsapp",
      "Wordpress premium / code",
      "Landing page support google ads",
      "Tayang 24 jam tanpa down",
      "Ringan, responsif dan mobile friendly",
    ],
  },
  {
    image: ServiceImg.serviceWeb5,
    services: [
      "Gratis domain .com 1 tahun pertama",
      "Gratis desain halaman website",
      "Gratis revisi minor selama website aktif",
      "Email business (owner@domain.com)",
      "Gratis tombol whatsapp",
      "Wordpress premium / code",
      "Landing page support google ads",
      "Tayang 24 jam tanpa down",
      "Ringan, responsif dan mobile friendly",
      "Free Google tag manager untuk upgrading setting Google ads",
    ],
  },
  {
    image: ServiceImg.serviceWeb6,
    services: [
      "Gratis domain .com 1 tahun pertama",
      "Gratis desain halaman website",
      "Gratis website 5 halaman",
      "Gratis revisi minor selama website aktif",
      "Email business (owner@domain.com)",
      "Gratis tombol whatsapp",
      "Wordpress premium / code",
      "Landing page support google ads",
      "Tayang 24 jam tanpa down",
      "Ringan, responsif dan mobile friendly",
      "Free Google tag manager untuk upgrading setting Google ads",
    ],
  },
];

export const MobilePricings = [
  {
    image: ServiceImg.serviceMobile1,
    services: [
      "IOS Aplikasi",
      "Gratis Desain UI / UX",
      "Custom Content Management System (CMS)",
      "Gratis Virtual Private Server",
      "Database Management (MySQL)",
      "Server OS (Linux)",
      "Gratis Domain.com",
      "Pemrograman Bahasa : PHP, Javascript, atau Custom",
      "3 Bulan Maintenance Gratis",
      "Konsultasi Bersama Pakar Aplikasi",
      "Customer Service 24 Jam",
      "Bebas Desain Layout Aplikasi",
    ],
  },
  {
    image: ServiceImg.serviceMobile2,
    services: [
      "Aplikasi Android & IOS",
      "Gratis Desain UI / UX",
      "Custom Content Management System (CMS)",
      "Gratis Virtual Private Server",
      "Database Management (MySQL)",
      "Server OS (Linux)",
      "Gratis Domain.com",
      "Pemrograman Bahasa : PHP, Javascript, atau Custom",
      "3 Bulan Maintenance Gratis",
      "Konsultasi Bersama Pakar Aplikasi",
      "Customer Service 24 Jam",
      "Bebas Desain Layout Aplikasi",
    ],
  },
  {
    image: ServiceImg.serviceMobile3,
    services: [
      "Android, IOS, dan Website Aplikasi",
      "Gratis Desain UI / UX",
      "Custom Content Management System (CMS)",
      "Gratis Virtual Private Server",
      "Database Management (MySQL)",
      "Server OS (Linux)",
      "Gratis Domain.com",
      "Pemrograman Bahasa : PHP, Javascript, atau Custom",
      "3 Bulan Maintenance Gratis",
      "Konsultasi Bersama Pakar Aplikasi",
      "Customer Service 24 Jam",
      "Bebas Desain Layout Aplikasi",
    ],
  },
];

export const GooglePricings = [
  {
    image: ServiceImg.serviceGoogle1,
    services: [
      "Saldo iklan : Rp. 1.000.000",
      "Anggaran Harian : Rp. 100.000",
      "Fee jasa & maintanance iklan (10%) : Rp. 100.000",
      "Update settingan iklan terbaru",
      "Gratis pemasangan google tag manager",
      "Meeting evaluasi beriklan online",
      "Transparasi laporan iklan",
      "Gratis riset kata kunci tak terbatas",
      "Optimasi iklan setiap hari",
      "Kualitas leads maksimal",
      "Gratis pengecekan performa website"
    ]
  },
  {
    image: ServiceImg.serviceGoogle2,
    services: [
      "Saldo iklan : Rp. 2.000.000",
      "Anggaran Harian : Rp. 100.000",
      "Fee jasa & maintanance iklan (10%) : Rp. 200.000",
      "Update settingan iklan terbaru",
      "Gratis pemasangan google tag manager",
      "Meeting evaluasi beriklan online",
      "Transparasi laporan iklan",
      "Gratis riset kata kunci tak terbatas",
      "Optimasi iklan setiap hari",
      "Kualitas leads maksimal",
      "Gratis pengecekan performa website"
    ]
  },
  {
    image: ServiceImg.serviceGoogle3,
    services: [
      "Saldo iklan : Rp. 3.000.000",
      "Anggaran Harian : Rp. 100.000",
      "Fee jasa & maintanance iklan (10%) : Rp. 300.000",
      "Update settingan iklan terbaru",
      "Gratis pemasangan google tag manager",
      "Meeting evaluasi beriklan online",
      "Transparasi laporan iklan",
      "Gratis riset kata kunci tak terbatas",
      "Optimasi iklan setiap hari",
      "Kualitas leads maksimal",
      "Gratis pengecekan performa website"
    ]
  },
  {
    image: ServiceImg.serviceGoogle4,
    services: [
      "Saldo iklan : Rp. 6.000.000",
      "Anggaran Harian : Rp. 200.000",
      "Fee jasa & maintanance iklan (10%) : Rp. 600.000",
      "Update settingan iklan terbaru",
      "Gratis pemasangan google tag manager",
      "Meeting evaluasi beriklan online",
      "Transparasi laporan iklan",
      "Gratis riset kata kunci tak terbatas",
      "Optimasi iklan setiap hari",
      "Kualitas leads maksimal",
      "Gratis pengecekan performa website"
    ]
  },
]

export const MetaPricings = [
  {
    image: ServiceImg.serviceMeta1,
    services: [
      "Saldo iklan : Rp. 1.000.000",
      "Anggaran Harian : Rp. 100.000",
      "Fee jasa : Rp. 250.000",
      "Iklan ditargetkan sesuai dengan minat dan usia",
      "Update settingan iklan terbaru",
      "Transparasi laporan iklan",
      "Penargetan iklan sesuai bisnis",
      "Optimasi iklan setiap hari",
      "Kualitas leads maksimal",
    ]
  },
  {
    image: ServiceImg.serviceMeta2,
    services: [
      "Saldo iklan : Rp. 2.000.000",
      "Anggaran Harian : Rp. 100.000",
      "Fee jasa : Rp. 500.000",
      "Iklan ditargetkan sesuai dengan minat dan usia",
      "Update settingan iklan terbaru",
      "Transparasi laporan iklan",
      "Penargetan iklan sesuai bisnis",
      "Optimasi iklan setiap hari",
      "Kualitas leads maksimal",
    ]
  },
  {
    image: ServiceImg.serviceMeta3,
    services: [
      "Saldo iklan : Rp. 3.000.000",
      "Anggaran Harian : Rp. 100.000",
      "Fee jasa : Rp. 750.000",
      "Free 3 konten carousel instagram dan facebook",
      "Iklan ditargetkan sesuai dengan minat dan usia",
      "Update settingan iklan terbaru",
      "Meeting evaluasi beriklan offline (JABODETABEK)",
      "Transparasi laporan iklan",
      "Penargetan iklan sesuai bisnis",
      "Optimasi iklan setiap hari",
      "Kualitas leads maksimal",
    ]
  },
  {
    image: ServiceImg.serviceMeta4,
    services: [
      "Saldo iklan : Rp. 6.000.000",
      "Anggaran Harian : Rp. 200.000",
      "Fee jasa : Rp. 1.500.000",
      "Free 6 konten carousel instagram dan facebook",
      "Iklan ditargetkan sesuai dengan minat dan usia",
      "Update settingan iklan terbaru",
      "Meeting evaluasi beriklan offline (JABODETABEK)",
      "Transparasi laporan iklan",
      "Penargetan iklan sesuai bisnis",
      "Optimasi iklan setiap hari",
      "Kualitas leads maksimal",
    ]
  },
]

export const SocialPricings = [
  {
    image: ServiceImg.serviceSocial1,
    services: [
      "15 Desain grafis feed",
      "30 post story",
      "Konsultasi & strategi",
      "Ide konten",
      "Copywriting & hastag resit"
    ]
  },
  {
    image: ServiceImg.serviceSocial2,
    services: [
      "30 desain grafis feed",
      "Konsultasi & strategi",
      "30 Post story",
      "Ide konten & konsep konten",
      "Copywriting & hastag riest",
      "1 kali revisi minor"
    ]
  },
  {
    image: ServiceImg.serviceSocial3,
    services: [
      "15 desain grafis feed",
      "1 video reels",
      "30 post story",
      "Ide konten & konsep konten",
      "Copywriting & hastag riset",
      "1 kali revisi minor"
    ]
  },
  {
    image: ServiceImg.serviceSocial4,
    services: [
      "15 desain grafis feed",
      "4 video reels",
      "30 post story",
      "Ide konten & konsep konten",
      "Copywriting & hastag riset",
      "Riset 1 kali revisi minor",
      "Report Performa akun"
    ]
  },
  {
    image: ServiceImg.serviceSocial5,
    services: [
      "5 foto/desain grafis feed",
      "8 video reels",
      "30 post story",
      "1x visit videografer",
      "Ide konten & konsep konten",
      "Copywriting & hastag riset",
      "Riset 2 kali revisi minor",
      "Report Performa akun"
    ]
  },
  {
    image: ServiceImg.serviceSocial6,
    services: [
      "20 desain grafis feed",
      "10 video reels",
      "30 post story",
      "2x visit videografer",
      "Ide konten & konsep konten",
      "Copywriting & hastag riset",
      "Riset 2 kali revisi minor",
      "Report Performa akun"
    ]
  },
]