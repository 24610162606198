import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import { Images, ProfileImg } from '../../assets/images';
import { Button, Grid, Icon, Paper, Stack, Toolbar, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { Colors } from '../../utils/colors';
import { BackgroundImg, BoxShadow, CENTER } from '../../utils/styles';
import { Icons } from '../../assets/icons';
import { highlight, Services } from './data';
import Footer from '../../components/navigation/footer';
import { useNavigate } from 'react-router';
import { isMobile, isTablet } from 'react-device-detect';
import Whatsapp from '../../components/navigation/whatsapp';

const HomePage = () => {
    const navigate = useNavigate()

    return (
        <div style={{ width: '100%', backgroundColor: "#fcfcfd" }}>
            <NavigationBar />
            <div style={{
                backgroundImage: `url(${Images.header})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '100vh',
                ...CENTER,
                position: 'relative'
            }}>
                <Stack direction={'column'} gap={3} alignItems={'center'}>
                    <Typography variant={isTablet ? 'h2' : isMobile ? 'h3' : "h1"} fontWeight={'800'} color={Colors.primary} textAlign={'center'}>Jasa Pembuatan Website</Typography>
                    <Typography variant={isTablet ? 'h2' : isMobile ? 'h3' : "h1"} fontWeight={'700'} color={'#fff'} textAlign={'center'}>Google Ads Specialist</Typography>
                    <Typography variant={isTablet ? 'h5' : isMobile ? 'h6' : "h4"} fontWeight={'300'} color={'#fff'} textAlign={'center'}>Marketing Agency</Typography>
                </Stack>
                <Stack
                    direction={'row'}
                    sx={{
                        backgroundColor: '#000',
                        borderRadius: '15px',
                        position: 'absolute',
                        bottom: '5%'
                    }}
                    alignItems={'center'}
                >
                    <div style={{ borderRadius: 15, backgroundColor: Colors.primary, padding: 10, ...CENTER, height: 50, aspectRatio: 1 }}>
                        <img src={Icons.phoneIcon} alt="" style={{ height: '80%', width: '80%', objectFit: 'contain' }} />
                    </div>
                    <div style={{ ...CENTER, padding: '0px 20px' }}>
                        <Link style={{ textDecoration: 'none'}} to="https://api.whatsapp.com/send/?phone=6281317284592&text=Halo%20Pak%20Alex,%20saya%20ingin%20bertanya%20tentang%20layanan%20arenvy..." target="_blank" ><Typography variant={isMobile || isTablet ? 'body2' : "h6"}  fontWeight={'600'} color="#fff">CONTACT US</Typography></Link>
                    </div>
                </Stack>
            </div>
            <Stack direction={'column'} gap={10} width={'100%'} sx={{ ...BackgroundImg }}>
                <div style={{ width: '100%', padding: '5% 0', ...CENTER }}>
                    <Stack direction={'row'} alignItems="center" width={isMobile || isTablet ? '90%' : "80%"} justifyContent={'space-between'} gap={8}>
                        <Stack direction={'column'} gap={3} width={isTablet || isMobile ? '100%' : '50%'} alignItems={'flex-start'}>
                            <Typography variant={isMobile ? 'h4' : "h3"} color="#000" fontWeight={'600'}>Tentang Kami</Typography>
                            <Stack direction={'column'} gap={1}>
                                <Typography variant="h5" fontWeight={'600'} color="#000">PT. Arenvy Teknologi Marketing</Typography>
                                <Typography variant="body1" color="#000" fontWeight={'400'} textAlign={'justify'}>PT Arenvy Teknologi Marketing berkomitmen teguh memberikan pelayanan kepada pelanggan seperti melayani bisnis sendiri agar terciptanya bisnis yang lebih baik melalui digitalisasi bagi banyak pelaku usaha.</Typography>
                            </Stack>
                            <Stack direction={isMobile ? 'column' : 'row'} gap={isMobile ? 3 : 5} alignItems={isMobile ? 'flex-start' : 'center'}>
                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                    <img src={Icons.projectIcon} style={{ width: 50, height: 50, objectFit: 'contain' }} alt="" />
                                    <Typography variant="body1" color="#000"><b style={{ color: '#0078d7' }}>183+ </b>Project Selesai</Typography>
                                </Stack>
                                <Stack direction={'row'} gap={1} alignItems={'center'}>
                                    <img src={Icons.teamsIcon} style={{ width: 50, height: 50, objectFit: 'contain' }} alt="" />
                                    <Typography variant="body1" color="#000"><b style={{ color: '#00b294' }}>50+ </b>Client Puas</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                <img src={Icons.datetimeIcon} style={{ width: 50, height: 50, objectFit: 'contain' }} alt="" />
                                <Typography variant="body1" color="#000"><b style={{ color: '#6b69d6' }}>5+ </b>Tahun Pengalaman</Typography>
                            </Stack>
                            <Link to="https://www.google.com/partners/agency?id=3848027179" target="_blank" style={{ width: isMobile ? '40%' : isTablet ? '30%' : '20%',  }}>
                            <img src={Images.googleBtn} style={{ aspectRatio: 1, objectFit: 'contain', width: '100%'}} alt="" /></Link>
                            <Button
                                variant={'contained'}
                                color={'warning'}
                                sx={{ backgroundColor: Colors.primary }}
                                startIcon={<img src={Icons.whatsappIcon} style={{ width: '3vh', height: '3vh', objectFit: 'contain' }} alt="" />}
                            >
                                <Link to="https://api.whatsapp.com/send/?phone=6281317284592&text=Halo%20Pak%20Alex,%20saya%20ingin%20bertanya%20tentang%20layanan%20arenvy..." style={{ textDecoration: 'none'}} target='_blank'><Typography variant="button" color="#fff" sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'clip',
                                    whiteSpace: 'nowrap'
                                }}>Hubungi Kami</Typography></Link>
                            </Button>
                        </Stack>
                        {
                            isTablet || isMobile ? null :
                                <Stack direction={'row'} width={'50%'} gap={3} alignItems={'center'}>
                                    <Stack direction={'column'} gap={3} width={'50%'}>
                                        <img src={ProfileImg.profileImg1} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" />
                                        <img src={ProfileImg.profileImg3} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" />
                                    </Stack>
                                    <img src={ProfileImg.profileImg2} style={{ width: '40%', height: '100%', objectFit: 'contain' }} alt="" />
                                </Stack>
                        }
                    </Stack>
                </div>
                <div style={{ width: '100%', ...CENTER }}>
                    <Stack direction={'column'} gap={5} width={isTablet || isMobile ? '90%' : "80%"}>
                        <Typography variant={isTablet ? 'h4' : isMobile ? 'h5' : "h3"} color="#000" width={'100%'} textAlign={isMobile ? 'center' : 'right'} fontWeight={'700'}>Layanan Terbaik Kami</Typography>
                        <Grid container spacing={isMobile || isTablet ? 1 : 3}>
                            {
                                Services.map((item, index) => (
                                    <Grid item lg={4} md={6} sm={6} xs={isTablet ? 6 : 12} key={index} height={'100%'}>
                                        <Paper elevation={3} sx={{ height: '100%', borderRadius: 3 }}>
                                            <Stack direction={'column'} gap={3} alignItems={'center'} padding={3}>
                                                <img src={item.image} style={{ width: '100%', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                                <Typography variant={isMobile || isTablet ? 'h6' : "h5"} textAlign={'center'} color="#000" fontWeight={'600'}>{item.title}</Typography>
                                                <Typography variant={isMobile || isTablet ? 'body2' : "body1"} color="#000" textAlign={'justify'}>{item.desc}</Typography>
                                                <Button
                                                    variant={'contained'}
                                                    color={'warning'}
                                                    sx={{ backgroundColor: Colors.primary }}
                                                    endIcon={<Icon fontSize={'medium'} sx={{ color: '#fff' }}>arrow_forward</Icon>}
                                                    onClick={() => navigate(item.navigate)}
                                                >
                                                    <Typography variant={isMobile || isTablet ? 'caption' : "button"} color="#fff" sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'clip',
                                                        whiteSpace: 'nowrap'
                                                    }}>Pelajari Lebih Lanjut</Typography>
                                                </Button>
                                            </Stack>
                                        </Paper>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Stack>
                </div>
                <div></div>
                <div style={{ width: '100%', ...CENTER }}>
                    <Stack direction={'column'} gap={5} width={isMobile || isTablet ? '90%' : "80%"} alignItems={'center'}>
                        <Typography variant={isTablet ? 'h4' : isMobile ? 'h5' : "h3"} color="#000" fontWeight={'700'}>Karya Gemilang Kami</Typography>
                        <Grid container spacing={isMobile || isTablet ? 1 : 3}>
                            {
                                highlight.map((item, index) => (
                                    <Grid item lg={3} md={3} sm={6} xs={isTablet ? 6 : 12} key={index}>
                                        <img
                                            src={item}
                                            style={{
                                                borderRadius: 15,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                ...BoxShadow
                                            }}
                                            alt=""
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <Button
                            onClick={() => navigate('/clients')}
                            variant={'contained'}
                            color={'warning'}
                            sx={{ backgroundColor: Colors.primary }}
                            endIcon={<Icon fontSize={'medium'} sx={{ color: '#fff' }}>arrow_forward</Icon>}
                        >
                            <Typography variant="button" color="#fff" sx={{
                                overflow: 'hidden',
                                textOverflow: 'clip',
                                whiteSpace: 'nowrap'
                            }}>Lihat Lebih Banyak</Typography>
                        </Button>
                    </Stack>
                </div>
                <Toolbar />
            </Stack>
            <Footer />
            <Whatsapp/>
        </div>
    )
}

export default HomePage;