import React from 'react'
import NavigationBar from '../../components/navigation/navigationBar';
import { Link } from 'react-router-dom';
import { Images, ProfileImg } from '../../assets/images';
import { Button, Grid, Icon, Paper, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { Colors } from '../../utils/colors';
import { BackgroundImg, BoxShadow, CENTER } from '../../utils/styles';
import { Icons } from '../../assets/icons';
import Footer from '../../components/navigation/footer';
import { achievement, missions, visions } from './data';
import { isMobile, isTablet } from 'react-device-detect';
import Whatsapp from '../../components/navigation/whatsapp';

const ProfilePage = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fcfcfd' }}>
            <NavigationBar />
            <Toolbar />
            <Stack direction={'column'} gap={10} width={'100%'} sx={{ ...BackgroundImg }}>
                <div style={{ width: '100%', padding: '5% 0', ...CENTER }}>
                    <Stack direction={'row'} alignItems="center" width={isMobile || isTablet ? '90%' : "80%"} justifyContent={'space-between'} gap={8}>
                        <Stack direction={'column'} gap={3} width={isMobile || isTablet ? '100%' : '50%'} alignItems={'flex-start'}>
                            <Typography variant={isMobile || isTablet ? "h4" : "h3"} color="#000" fontWeight={'600'}>Profile</Typography>
                            <Stack direction={'column'} gap={1}>
                                <Typography variant="h5" fontWeight={'600'} color="#000">PT. Arenvy Teknologi Marketing</Typography>
                                <Typography variant={isMobile || isTablet ? 'body2' : "body1"} color="#000" fontWeight={'400'} textAlign={'justify'} lineHeight={2}>
                                    PT Arenvy Teknologi Marketing telah menjadi mitra terpercaya bagi banyak bisnis di seluruh Indonesia dan mendapatkan pengalaman di industri Digital seperti Media Periklanan Google Ads, Pembuatan Website untuk Company Profile, E-commerce maupun Website landing page khsusus untuk di iklankan di Google Ads. Kami juga menerima Jasa pembuatan Aplikasi seperti Sistem Management Kantor, HRIS, Mobile App dan juga aplikasi custom sesuai dengan permintaan client kami. Selain itu kami juga menyediakan Jasa Social media specialist yang menunjang tinggi nilai Brand awarness bisnis Anda agar bisnis anda bisa lebih mudah di kenal pengguna internet yang berpengaruh terhadap omzet bisnis Anda. Sebagai mitra Digital Marketing, kami terus memberikan solusi Inovatif & Efisien ke setiap bisnis dengan Kualitas Terbaik dan Respon Cepat & Layanan Purna Jual Profesional yang dapat Anda percaya.
                                </Typography>
                            </Stack>
                            <Link to="https://www.google.com/partners/agency?id=3848027179" target="_blank" style={{ width: isMobile ? '40%' : isTablet ? '30%' : '20%', }}>
                                <img src={Images.googleBtn} style={{ aspectRatio: 1, objectFit: 'contain', width: '100%' }} alt="" /></Link>
                        </Stack>
                        {
                            isMobile || isTablet ? null :
                                <Stack direction={'row'} width={'50%'} gap={3} alignItems={'center'}>
                                    <Stack direction={'column'} gap={3} width={'50%'}>
                                        <img src={ProfileImg.profileImg1} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" />
                                        <img src={ProfileImg.profileImg3} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" />
                                    </Stack>
                                    <img src={ProfileImg.profileImg2} style={{ width: isTablet ? '30%' : '40%', height: '100%', objectFit: 'contain' }} alt="" />
                                </Stack>
                        }
                    </Stack>
                </div>
                <div style={{ width: '100%', ...CENTER, position: 'relative' }}>
                    <Stack direction={'column'} gap={8} width={isMobile || isTablet ? '90%' : '80%'} position={'relative'} sx={{ zIndex: 2 }}>
                        <div style={{ width: isTablet ? '70%' : isMobile ? '100%' : '50%', backgroundColor: '#fff', borderRadius: 10, boxShadow: '25px 25px 0px 1px rgba(0,0,0,0.20)' }}>
                            <Stack direction={'column'} alignItems={'center'} gap={4} padding={3}>
                                <img src={Icons.lampIcon} style={{ width: '20%', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                <Typography variant={isMobile || isTablet ? "h4" : "h3"} color="#000" fontWeight={'700'}>Visi</Typography>
                                <Stack direction={'column'} gap={1}>
                                    {
                                        visions.map((item, index) => (
                                            <Stack direction={'row'} key={index} alignItems={'center'} gap={1}>
                                                <Icon fontSize={'medium'} sx={{ color: '#000' }}>check_circle_outline</Icon>
                                                <Typography variant={isMobile || isTablet ? 'caption' : "body1"} color="#000">{item}</Typography>
                                            </Stack>
                                        ))
                                    }
                                </Stack>
                            </Stack>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <div style={{ width: isTablet ? '80%' : isMobile ? '100%' : '50%', backgroundColor: '#fff', borderRadius: 10, boxShadow: '25px 25px 0px 1px rgba(0,0,0,0.20)' }}>
                                <Stack direction={'column'} alignItems={'center'} gap={4} padding={3}>
                                    <img src={Icons.rocketIcon} style={{ width: '20%', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                    <Typography variant={isMobile || isTablet ? "h4" : "h3"} color="#000" fontWeight={'700'}>Misi</Typography>
                                    <Stack direction={'column'} gap={1}>
                                        {
                                            missions.map((item, index) => (
                                                <Stack direction={'row'} key={index} alignItems={'center'} gap={1}>
                                                    <Icon fontSize={'medium'} sx={{ color: '#000' }}>check_circle_outline</Icon>
                                                    <Typography variant={isMobile || isTablet ? 'caption' : "body1"} color="#000">{item}</Typography>
                                                </Stack>
                                            ))
                                        }
                                    </Stack>
                                </Stack>
                            </div>
                        </div>
                    </Stack>
                    <img
                        src={ProfileImg.profileSection1}
                        style={{
                            width: isMobile || isTablet ? '80vw' : '30vw',
                            aspectRatio: 1,
                            objectFit: 'contain',
                            position: 'absolute',
                            top: 0,
                            right: isMobile || isTablet ? 0 : '10%',
                            zIndex: 0
                        }} alt=""
                    />
                    <img
                        src={ProfileImg.profileSection2}
                        style={{
                            width: isTablet ? '80vw' : isMobile ? '100vw' : '30vw',
                            aspectRatio: isMobile || isTablet ? 1 : 16 / 9,
                            objectFit: 'contain',
                            position: 'absolute',
                            bottom: 0,
                            left: isMobile || isTablet ? '0' : '5%',
                            zIndex: 0
                        }} alt=""
                    />
                </div>
                <div></div>
                <div style={{ width: '100%', ...CENTER }}>
                    <Stack direction={'column'} gap={5} width={isMobile || isTablet ? '90%' : '80%'}>
                        <Typography variant={isTablet ? "h4" : isMobile ? 'h5' : "h3"} color="#000" width={'100%'} textAlign={isMobile ? 'center' : 'right'} fontWeight={'700'}>Layanan Terbaik Kami</Typography>
                        <Grid container spacing={isMobile || isTablet ? 1 : 3} justifyContent={'center'}>
                            {
                                achievement.map((item, index) => (
                                    <Grid item lg={4} md={6} sm={6} xs={isTablet ? 6 : 12} key={index} height={'100%'}>
                                        <Paper elevation={3} sx={{ height: '100%', borderRadius: 3 }}>
                                            <Stack direction={'column'} gap={3} alignItems={'center'} padding={3}>
                                                <img src={item.image} style={{ width: '100%', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                                <Typography variant={isMobile || isTablet ? 'h6' : "h5"} color="#000" fontWeight={'600'} textAlign={'center'}>{item.title}</Typography>
                                                <Typography variant={isMobile || isTablet ? 'caption' : "body1"} color="#000" textAlign={'justify'}>{item.desc}</Typography>
                                            </Stack>
                                        </Paper>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Stack>
                </div>
                <div></div>
                <div style={{ width: '100%', ...CENTER }}>
                    <Stack direction={'column'} gap={5} width={isMobile || isTablet ? '90%' : '80%'}>
                        <Typography variant={isMobile || isTablet ? "h4" : "h3"} color="#000" textAlign={'center'} fontWeight={'700'}>Kontak Kami</Typography>
                        <Stack
                            direction={isMobile ? 'column' : 'row'}
                            alignItems={isMobile || isTablet ? 'flex-start' : 'center'}
                            justifyContent={'space-around'}
                            gap={3}
                            sx={{
                                backgroundColor: '#fff',
                                width: '100%',
                                borderRadius: 5,
                                padding: isMobile || isTablet ? '20px' : '20px 0',
                                ...BoxShadow
                            }}
                        >
                            <Link to="mailto:aravedtech@gmail.com" target='_blank' style={{ textDecoration: 'none' }}>
                                <Stack direction={'row'} gap={2} alignItems={'center'}>
                                    <img src={Icons.emailGold} style={{ width: 40, height: 40, objectFit: 'contain' }} alt="" />
                                    <Stack direction={'column'}>
                                        <Typography variant={isMobile || isTablet ? "body2" : "body1"} color="#000" fontWeight={'600'}>Email</Typography>
                                        <Typography variant={isMobile || isTablet ? "caption" : "body2"} color="#9a9a9a">aravedtech@gmail.com</Typography>
                                    </Stack>
                                </Stack>
                            </Link>
                            <Link to="https://api.whatsapp.com/send/?phone=6281261661033&text=Halo%20Pak%20Aldi,%20saya%20ingin%20bertanya%20tentang%20layanan%20arenvy..." style={{ textDecoration: 'none' }} target='_blank'>
                                <Stack direction={'row'} gap={2} alignItems={'center'}>
                                    <img src={Icons.whatsappGold} style={{ width: 40, height: 40, objectFit: 'contain' }} alt="" />
                                    <Stack direction={'column'}>
                                        <Typography variant={isMobile || isTablet ? "body2" : "body1"} color="#000" fontWeight={'600'}>Whatsapp {'(Aldi)'}</Typography>
                                        <Typography variant={isMobile || isTablet ? "caption" : "body2"} color="#9a9a9a">+62 812-6166-1033</Typography>
                                    </Stack>
                                </Stack></Link>
                            <Link to="https://api.whatsapp.com/send/?phone=6281317284592&text=Halo%20Pak%20Alex,%20saya%20ingin%20bertanya%20tentang%20layanan%20arenvy..." style={{ textDecoration: 'none' }} target='_blank'>
                                <Stack direction={'row'} gap={2} alignItems={'center'}>
                                    <img src={Icons.whatsappGold} style={{ width: 40, height: 40, objectFit: 'contain' }} alt="" />
                                    <Stack direction={'column'}>
                                        <Typography variant={isMobile || isTablet ? "body2" : "body1"} color="#000" fontWeight={'600'}>Whatsapp {'(Alex)'}</Typography>
                                        <Typography variant={isMobile || isTablet ? "caption" : "body2"} color="#9a9a9a">+62 813-1728-4592</Typography>
                                    </Stack>
                                </Stack>
                            </Link>
                        </Stack>
                        <Stack direction={isMobile || isTablet ? 'column' : 'row'} width={'100%'} gap={5}>
                            <div style={{ width: isMobile || isTablet ? '100%' : '40%', backgroundColor: '#fff', borderRadius: 10, ...BoxShadow }}>
                                <Stack direction={'column'} gap={3} padding={3} alignItems={"flex-start"}>
                                    <TextField
                                        type="text"
                                        size="medium"
                                        label={'Nama'}
                                        sx={{ backgroundColor: "#f7f7f7", width: '100%' }}
                                        InputProps={{ sx: { borderRadius: 2 } }}
                                    />
                                    <TextField
                                        type="text"
                                        size="medium"
                                        label={'Email'}
                                        sx={{ backgroundColor: "#f7f7f7", width: '100%' }}
                                        InputProps={{ sx: { borderRadius: 2 } }}
                                    />
                                    <TextField
                                        type="text"
                                        size="medium"
                                        label={'Nomor Handphone'}
                                        sx={{ backgroundColor: "#f7f7f7", width: '100%' }}
                                        InputProps={{ sx: { borderRadius: 2 } }}
                                    />
                                    <TextField
                                        type="text"
                                        size="medium"
                                        label={'Pesan'}
                                        multiline
                                        rows={5}
                                        sx={{ backgroundColor: "#f7f7f7", width: '100%' }}
                                        InputProps={{ sx: { borderRadius: 2 } }}
                                    />
                                    <Button color={'primary'} sx={{ backgroundColor: Colors.primary, color: '#fff' }} variant={'contained'}>Kirim Teks</Button>
                                </Stack>
                            </div>
                            <div style={{ width: isMobile || isTablet ? '100%' : '60%', backgroundColor: '#fff', borderRadius: 10 }}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.8179522356073!2d106.92965742458115!3d-6.382741262934035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6994a4008ad667%3A0x918879bf3e100158!2sKw.%20Niaga%20Citra%20Gran%2C%20Jl.%20Alternatif%20Cibubur%2C%20RT.001%2FRW.011%2C%20Nagrak%2C%20Kec.%20Gn.%20Putri%2C%20Kabupaten%20Bogor%2C%20Jawa%20Barat%2017435%2C%20Indonesia!5e0!3m2!1sen!2sus!4v1701336021114!5m2!1sen!2sus"
                                    style={{ borderRadius: 10, width: '100%', height: '100%', borderWidth: 0, aspectRatio: isMobile || isTablet ? 1 : undefined }}
                                    allowFullScreen={true}
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    title='Address'
                                ></iframe>
                            </div>
                        </Stack>
                    </Stack>
                </div>
                <Toolbar />
            </Stack>
            <Footer />
            <Whatsapp/>
        </div>
    )
}

export default ProfilePage;