import { ProfileImg } from "../../assets/images";

export const visions = [
  "Menciptakan bisnis yang lebih baik melalui digitalisasi bagi banyak pelaku usaha",
  "Menjadi mitra business multinational dan di akui oleh dunia",
];

export const missions = [
  "Menjadi perusahaan digital yang selalu mengutamakan pelayanan yang relevan dan transparan dalam setiap kerjasama",
  "Pengembangan digital. Menjadi perusahaan digital yang selalu memberikan hasil ketepatan mutu dan waktu dalam setiap kerjasama pengembangan digital",
  "Selalu bekerja keras dalam memberikan pelayanan kepada pelanggan seperti mengelola bisnis sendiri agar dapat berkembang bersama di era digitalisasi",
  "Update terhadap perkembangan dan perubahan teknologi digital agar dapat memenuhi kebutuhan dan memberi solusi digital kepada pelanggan",
];

export const achievement = [
  {
    image: ProfileImg.profileVector1,
    title: "183+ Project Selesai",
    desc: "Lebih dari 183 proyek telah kami selesaikan dengan penuh dedikasi dan kesungguhan. Bergabunglah dengan kami untuk menghadirkan keberhasilan pada proyek Anda berikutnya!",
  },
  {
    image: ProfileImg.profileVector2,
    title: "50+ Client Puas",
    desc: "Dengan keunggulan pelayanan, kami telah meraih kepercayaan dan kepuasan lebih dari 50 klien yang melihat hasil positif dari layanan kami. Bergabunglah dengan kami dan jadilah bagian dari pengalaman yang memuaskan!",
  },
  {
    image: ProfileImg.profileVector3,
    title: "5+ Tahun Pengalaman",
    desc: "Dengan lebih dari 5 tahun pengalaman yang mendalam dalam industri ini, kami telah memperoleh pengetahuan dan keahlian yang solid. Percayakan proyek Anda kepada kami dan rasakan keunggulan dari pengalaman kami yang teruji!",
  },
];