import { ProjectImg } from "../../assets/images";
import { ClientImg } from "../../assets/icons";

export const clients = [
  {
    image:
      "https://itbox.id/wp-content/uploads/2023/02/Advertising-With-Google-Ads.webp",
    name: "Andi Wirawan",
    position: "Customer Google Ads",
    testi: "Saya, Andi Wirawan, sangat senang dengan hasil iklan Google Ads! Penjualan online saya meningkat secara signifikan setelah menggunakan layanan ini. Terima kasih Google Ads!",
  },
  {
    image: "https://media.licdn.com/dms/image/D4D12AQHXIjXFHv6hBQ/article-cover_image-shrink_720_1280/0/1676953481892?e=2147483647&v=beta&t=FCVAoWujPBCFHxfTGX652DfcReFBDTx_QmFZQCtUnlI",
    name: "Rini Fitriani",
    position: "Customer Meta Ads",
    testi: "Meta Ads benar-benar membawa iklan kami ke tingkat yang baru. Nama saya, Rini Fitriani, dan saya melihat perkembangan yang luar biasa dalam mencapai target pasar kami. Terima kasih Meta Ads!"
  },
  {
    image:
      "https://caraguna.com/wp-content/uploads/2022/09/website-company-profile.png",
    name: "Agus Santoso",
    position: "Customer Web",
    testi: "PembuatWeb telah membantu bisnis saya tumbuh. Saya, Agus Santoso, sangat puas dengan desain website yang modern dan fungsional. Mereka mengerti kebutuhan bisnis lokal saya."
  },
  {
    image:
      "https://static.promediateknologi.id/crop/0x0:0x0/750x500/webp/photo/2022/08/11/3174203641.png",
    name: "Ratna Putri",
    position: "Customer Social Media",
    testi: "Manajemen sosial media dari tim lokal ini sungguh luar biasa. Saya, Ratna Putri, melihat interaksi pelanggan meningkat dan brand awareness bertambah pesat. Terima kasih untuk layanan yang luar biasa!"
  },
  {
    image:
      "https://itbox.id/wp-content/uploads/2023/02/Advertising-With-Google-Ads.webp",
    name: "Dedi Pratama",
    position: "Customer Google Ads",
    testi: "Google Ads benar-benar membantu toko online saya berkembang. Saya, Dedi Pratama, melihat peningkatan pesat dalam kunjungan situs dan konversi setelah menggunakan layanan ini."
  },
  {
    image:
      "https://media.licdn.com/dms/image/D4D12AQHXIjXFHv6hBQ/article-cover_image-shrink_720_1280/0/1676953481892?e=2147483647&v=beta&t=FCVAoWujPBCFHxfTGX652DfcReFBDTx_QmFZQCtUnlI",
    name: "Nita Wulandari",
    position: "Customer Meta Ads",
    testi: "Saya, Nita Wulandari, ingin berterima kasih kepada Meta Ads. Mereka membantu mengoptimalisasi kampanye iklan kami, dan hasilnya sangat memuaskan. Iklan kami sekarang lebih efektif dan efisien."
  },
  {
    image:
      "https://caraguna.com/wp-content/uploads/2022/09/website-company-profile.png",
    name: "Yoga Pratama",
    position: "Customer Website",
    testi: "PembuatWeb telah memberikan solusi pembuatan website yang luar biasa. Nama saya, Yoga Pratama, dan website bisnis kuliner saya sekarang menarik perhatian lebih banyak pelanggan."
  },
  {
    image:
      "https://static.promediateknologi.id/crop/0x0:0x0/750x500/webp/photo/2022/08/11/3174203641.png",
    name: "Rina Indriani",
    position: "Customer Social Media",
    testi: "Manajemen sosial media dari tim ini sungguh mengagumkan. Saya, Rina Indriani, melihat pertumbuhan followers yang konsisten dan interaksi yang meningkat. Mereka benar-benar mengerti pasar lokal."
  },
  {
    image:
      "https://itbox.id/wp-content/uploads/2023/02/Advertising-With-Google-Ads.webp",
    name: "Fajar Nugroho",
    position: "Customer Google Ads",
    testi: "Google Ads adalah kunci kesuksesan kami. Saya, Fajar Nugroho, melihat ROI yang fantastis setelah menggunakan platform iklan ini. Layanan yang sangat direkomendasikan!"
  },
  {
    image:
      "https://media.licdn.com/dms/image/D4D12AQHXIjXFHv6hBQ/article-cover_image-shrink_720_1280/0/1676953481892?e=2147483647&v=beta&t=FCVAoWujPBCFHxfTGX652DfcReFBDTx_QmFZQCtUnlI",
    name: "Riza Pratama",
    position: "Customer Meta Ads",
    testi: "Terima kasih Meta Ads! Saya, Riza Pratama, sangat senang dengan targeting iklan yang mereka lakukan. Mereka membantu kami mencapai audiens yang tepat dan hasilnya sangat mengesankan."
  },
];

export const milestones = [
  ProjectImg.projectImg1,
  ProjectImg.projectImg2,
  ProjectImg.projectImg3,
  ProjectImg.projectImg4,
  ProjectImg.projectImg5,
  ProjectImg.projectImg6,
  ProjectImg.projectImg7,
  ProjectImg.projectImg8,
  ProjectImg.projectImg9,
  ProjectImg.projectImg10,
];

export const clientLogo = [
  ClientImg.clientImg1,
  ClientImg.clientImg2,
  ClientImg.clientImg3,
  ClientImg.clientImg4,
  ClientImg.clientImg5,
  ClientImg.clientImg6,
  ClientImg.clientImg7,
  ClientImg.clientImg8,
  ClientImg.clientImg9,
  ClientImg.clientImg10,
  ClientImg.clientImg11,
  ClientImg.clientImg12,
  ClientImg.clientImg13,
  ClientImg.clientImg14,
  ClientImg.clientImg15,
  ClientImg.clientImg16,
  ClientImg.clientImg17,
];
