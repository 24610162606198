import React from 'react'
import { Grid, Stack, Toolbar, Typography } from '@mui/material'
import Footer from '../../components/navigation/footer';
import NavigationBar from '../../components/navigation/navigationBar';
import { BackgroundImg, BoxShadow, CENTER } from '../../utils/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode } from 'swiper/modules';
import { clientLogo, clients, milestones } from './data';
import { isMobile, isTablet } from 'react-device-detect';
import Whatsapp from '../../components/navigation/whatsapp';

const ClientsPage = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fcfcfd' }}>
            <NavigationBar />
            <Toolbar />
            <Toolbar />
            <Stack direction={'column'} gap={10} width={'100%'} sx={{ ...BackgroundImg }}>
                <div style={{ width: '100%', ...CENTER }}>
                    <Stack direction={'column'} gap={5} width={'80%'}>
                        <Typography variant="h3" color="#000" fontWeight={"700"}>Testimonial</Typography>
                        <div style={{ width: '100%', backgroundColor: '#e6e6e6', borderRadius: 15, padding: '5% 0' }}>
                            <Stack direction={isMobile || isTablet ? 'column' : 'row'} alignItems={'center'} width={'100%'} justifyContent={isMobile || isTablet ? 'flex-start' : 'space-between'}>
                                {/* <div style={{ width: isMobile || isTablet ? '100%' : '30%', ...CENTER }}>
                                    <Stack direction={'column'} gap={1} padding={isMobile || isTablet ? 4 : 8}>
                                        <Typography variant={isMobile || isTablet ? 'body1' : "h6"} fontWeight={'600'} color="#000">Lorem ipsum dolor sit amet</Typography>
                                        <Typography variant={isMobile || isTablet ? 'body2' : "body1"} color="#000" textAlign={'justify'}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus illum nobis, dolores eveniet delectus voluptates? Quaerat perferendis minus commodi, similique aspernatur aliquid debitis dignissimos, at consectetur distinctio sapiente dolorem fugiat!</Typography>
                                    </Stack>
                                </div> */}
                                <div style={{ width: isMobile || isTablet ? "100%" : '100%', padding: '0 40px' }}>
                                    <Swiper
                                        slidesPerView={isMobile ? 1.2 : 2.8}
                                        spaceBetween={isMobile || isTablet ? 10 : 30}
                                        freeMode={true}
                                        modules={[FreeMode]}

                                    >
                                        {
                                            clients.map((item, index) => (
                                                <SwiperSlide key={index}>
                                                    <div style={{ width: '100%', borderRadius: 10, ...BoxShadow, backgroundColor: '#fff' }}>
                                                        <Stack direction={'column'}>
                                                            <img style={{ width: '100%', aspectRatio: 1, objectFit: 'contain', borderRadius: '10px 10px 0 0' }} src={item.image} alt="" />
                                                            <div style={{ padding: isMobile || isTablet ? 10 : 20 }}>
                                                                <Typography variant={isMobile || isTablet ? 'caption' : "body2"} color="#000" textAlign={'justify'}>{item.testi}</Typography>
                                                                <Typography variant={isMobile || isTablet ? 'body2' : "body1"} fontWeight={'700'} sx={{ marginTop: 3 }} color="#000">{item.name}</Typography>
                                                                <Typography variant={isMobile || isTablet ? 'caption' : "body2"} color="#000">{item.position}</Typography>
                                                            </div>
                                                        </Stack>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                            </Stack>
                        </div>
                    </Stack>
                </div>
                <div></div>
                <div style={{ width: '100%', ...CENTER }}>
                    <Stack direction={'column'} gap={5} width={'80%'}>
                        <Typography variant={isTablet ? 'h4' : isMobile ? 'h5' : "h3"} color="#000" fontWeight={'700'} width={'100%'} textAlign={'center'}>Karya Kami</Typography>
                        <Grid container spacing={isMobile || isTablet ? 1 : 3} justifyContent={'center'}>
                            {
                                milestones.map((item, index) => (
                                    <Grid key={index} item lg={3} md={6} sm={6} xs={isTablet ? 6 : 12}>
                                        <img src={item} style={{ width: '100%', aspectRatio: 9 / 16, borderRadius: 15, objectFit: 'cover' }} alt="" />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Stack>
                </div>
                <div></div>
                <div style={{ width: '100%', ...CENTER }}>
                    <Stack direction={'column'} gap={5} width={'80%'}>
                        <Typography variant={isTablet ? 'h4' : isMobile ? 'h5' : "h3"} color="#000" fontWeight={'700'} width={'100%'} textAlign={isMobile ? 'center' : 'right'}>Klien Kami</Typography>
                        <Grid container spacing={isMobile || isTablet ? 4 : 8} justifyContent={'center'}>
                            {
                                clientLogo.map((item, index) => (
                                    <Grid key={index} item lg={2} md={3} sm={3} xs={isTablet ? 3 : 6}>
                                        <img src={item} style={{ width: '100%', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Stack>
                </div>
                <Toolbar />
            </Stack >
            <Footer />
            <Whatsapp/>
        </div >
    )
}

export default ClientsPage;