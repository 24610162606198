import React from "react";
import TitlePage from "./routerTitle";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RouterInit } from "./routerInit";

import HomePage from "../pages/Home/home";
import ProfilePage from "../pages/Profile/profile";
import ServicesWebDevelopmentPage from "../pages/Services/webDevelopment";
import ServicesMobileDevelopmentPage from "../pages/Services/mobileDevelopment";
import ServicesGoogleManagementPage from "../pages/Services/googleManagement";
import ServicesMetaManagementPage from "../pages/Services/metaManagement";
import ServicesSocialMediaManagementPage from "../pages/Services/socialManagement";
import ClientsPage from "../pages/Clients/clients";

const PageRoutes = () => {
    return (
        <Router>
            <RouterInit />
            <Routes>
                <Route path="/" element={<TitlePage title="Beranda | Arenvy"><HomePage /></TitlePage>} />
                <Route path="/profile" element={<TitlePage title="Profil & Kontak | Arenvy"><ProfilePage /></TitlePage>} />
                <Route path="/services/web-development" element={<TitlePage title="Layanan Web Development | Arenvy"><ServicesWebDevelopmentPage /></TitlePage>} />
                <Route path="/services/mobile-development" element={<TitlePage title="Layanan Mobile Development | Arenvy"><ServicesMobileDevelopmentPage /></TitlePage>} />
                <Route path="/services/google-ads-management" element={<TitlePage title="Layanan Google Ads Management | Arenvy"><ServicesGoogleManagementPage /></TitlePage>} />
                <Route path="/services/meta-ads-management" element={<TitlePage title="Layanan Meta Ads Management | Arenvy"><ServicesMetaManagementPage /></TitlePage>} />
                <Route path="/services/social-media-management" element={<TitlePage title="Layanan Social Media Management | Arenvy"><ServicesSocialMediaManagementPage /></TitlePage>} />
                <Route path="/clients" element={<TitlePage title="Klien & Testimonial | Arenvy"><ClientsPage /></TitlePage>} />
            </Routes>
        </Router>
    )
}

export default PageRoutes;