export const Hrefs = [
  { label: "Home", navigate: "/" },
  { label: "Profil & Kontak", navigate: "/profile" },
  { label: "Layanan", navigate: "" },
  { label: "Klien & Testimonial", navigate: "/clients" },
];

export const Services = [
  { label: "Web Development", navigate: "/services/web-development" },
  {
    label: "Web & Mobile App Development",
    navigate: "/services/mobile-development",
  },
  {
    label: "Social Media Management",
    navigate: "/services/social-media-management",
  },
  {
    label: "Google Ads Management",
    navigate: "/services/google-ads-management",
  },
  {
    label: "Meta Ads Management",
    navigate: "/services/meta-ads-management",
  },
  { label: "Digital Marketing", navigate: "/" },
];
