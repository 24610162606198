export const Icons = {
  logo: require("./logo.png"),
  datetimeIcon: require("./datetime-icon.png"),
  lampIcon: require("./lamp-icon.png"),
  projectIcon: require("./project-icon.png"),
  rocketIcon: require("./rocket-icon.png"),
  teamsIcon: require("./teams-icon.png"),
  phoneIcon: require("./phone-icon.png"),
  whatsappIcon: require("./whatsapp-icon.png"),
  emailGold: require("./email-gold.png"),
  whatsappGold: require("./whatsapp-gold.png")
};

export const ClientImg = {
  clientImg1: require("./clients-1.png"),
  clientImg2: require("./clients-2.png"),
  clientImg3: require("./clients-3.png"),
  clientImg4: require("./clients-4.png"),
  clientImg5: require("./clients-5.png"),
  clientImg6: require("./clients-6.png"),
  clientImg7: require("./clients-7.png"),
  clientImg8: require("./clients-8.png"),
  clientImg9: require("./clients-9.png"),
  clientImg10: require("./clients-10.png"),
  clientImg11: require("./clients-11.png"),
  clientImg12: require("./clients-12.png"),
  clientImg13: require("./clients-13.png"),
  clientImg14: require("./clients-14.png"),
  clientImg15: require("./clients-15.png"),
  clientImg16: require("./clients-16.png"),
  clientImg17: require("./clients-17.png"),
};
