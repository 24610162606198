import { Button, Icon, Stack, Toolbar, Typography } from '@mui/material';
import React from 'react'
import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { Icons } from '../../assets/icons';
import { CENTER } from '../../utils/styles';
import { Link } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate()
    return (
        <div style={{ width: '100%', backgroundColor: '#1c1c1c', padding: '5% 0' }}>
            <div style={{ width: '100%', ...CENTER }}>
                <Stack direction={isTablet || isMobile ? 'column' : 'row'} width={isMobile || isTablet ? '90%' : '80%'} gap={3} justifyContent={'space-between'}>
                    <Stack direction={isTablet ? 'row' : 'column'} gap={2} width={isMobile || isTablet ? '100%' : '30%'}>
                        <img src={Icons.logo} style={{ width: 100, aspectRatio: 1, objectFit: 'contain' }} alt="" />
                        <Typography variant={isMobile || isTablet ? 'caption' : "body1"} color="#fff" textAlign={'justify'}>Arenvy Technology adalah agensi yang melayani jasa pembuatan website dan aplikasi yang telah berdiri sejak 2018, dan kami menyediakan solusi berbagai aplikasi dan software untuk segala jenis kebutuhan bisnis dan perusahaan Anda.</Typography>
                    </Stack>
                    <Stack direction={isMobile ? 'column' : 'row'} width={isMobile || isTablet ? '100%' : '70%'} justifyContent={'space-between'} gap={isMobile ? 3 : 0}>
                        <Stack direction={'column'} gap={2} width={isMobile ? '100%' : '40%'} color={"#fff"}>
                            <Typography variant={isMobile || isTablet ? 'body1' : "h6"} fontWeight={'700'} color="#fff">PAGES</Typography>
                            <Button onClick={() => navigate('/')} variant={'text'} color={"inherit"}>
                                <Stack direction={'row'} gap={1} width={'100%'} justifyContent={'flex-start'} alignItems={'center'}>
                                    <Icon fontSize={'medium'} sx={{ color: '#fff' }}>chevron_right</Icon>
                                    <Typography variant={isMobile || isTablet ? 'caption' : "button"} color="#fff">HOME</Typography>
                                </Stack>
                            </Button>
                            <Button onClick={() => navigate('/profile')} variant={'text'} color={"inherit"}>
                                <Stack direction={'row'} gap={1} width={'100%'} justifyContent={'flex-start'} alignItems={'center'}>
                                    <Icon fontSize={'medium'} sx={{ color: '#fff' }}>chevron_right</Icon>
                                    <Typography variant={isMobile || isTablet ? 'caption' : "button"} color="#fff">TENTANG KAMI</Typography>
                                </Stack>
                            </Button>
                            <Button onClick={() => navigate('/services/web-development')} variant={'text'} color={"inherit"}>
                                <Stack direction={'row'} gap={1} width={'100%'} justifyContent={'flex-start'} alignItems={'center'}>
                                    <Icon fontSize={'medium'} sx={{ color: '#fff' }}>chevron_right</Icon>
                                    <Typography variant={isMobile || isTablet ? 'caption' : "button"} color="#fff">LAYANAN</Typography>
                                </Stack>
                            </Button>
                            <Button onClick={() => navigate('/clients')} variant={'text'} color={"inherit"}>
                                <Stack direction={'row'} gap={1} width={'100%'} justifyContent={'flex-start'} alignItems={'center'}>
                                    <Icon fontSize={'medium'} sx={{ color: '#fff' }}>chevron_right</Icon>
                                    <Typography variant={isMobile || isTablet ? 'caption' : "button"} color="#fff">PORTOFOLIO</Typography>
                                </Stack>
                            </Button>
                        </Stack>
                        <Stack direction={'column'} gap={2} width={isTablet ? '60%' : isMobile ? '100%' : '40%'} color={"#fff"}>
                            <Typography variant={isMobile || isTablet ? 'body1' : "h6"} fontWeight={'700'} color="#fff">CONTACTS</Typography>
                            <Stack direction={'row'} alignItems={'center'} gap={2}>
                                <Icon fontSize={'medium'} sx={{ color: '#fff' }}>place</Icon>
                                <Typography variant={isMobile || isTablet ? 'caption' : "body2"} color="#fff">Jalan Transyogi, Kawasan Niaga Citra Gran Blok R3 No 12 Cibubur - Jakarta</Typography>
                            </Stack>
                            <Link to="mailto:aravedtech@gmail.com" target='_blank' style={{ textDecoration: 'none'}}>
                            <Stack direction={'row'} alignItems={'center'} gap={2}>
                                <Icon fontSize={'medium'} sx={{ color: '#fff' }}>mail</Icon>
                                <Typography variant={isMobile || isTablet ? 'caption' : "body2"} color="#fff">aravedtech@gmail.com</Typography>
                            </Stack></Link>

                            <Link to="https://api.whatsapp.com/send/?phone=6281261661033&text=Halo%20Pak%20Aldi,%20saya%20ingin%20bertanya%20tentang%20layanan%20arenvy..." target='_blank' style={{ textDecoration: 'none'}}>
                            <Stack direction={'row'} alignItems={'center'} gap={2}>
                                <Icon fontSize={'medium'} sx={{ color: '#fff' }}>call</Icon>
                                <Typography variant={isMobile || isTablet ? 'caption' : "body2"} color="#fff">+62 812-6166-1033 {'(Aldi)'}</Typography>
                            </Stack></Link>

                            <Link to="https://api.whatsapp.com/send/?phone=6281317284592&text=Halo%20Pak%20Alex,%20saya%20ingin%20bertanya%20tentang%20layanan%20arenvy..." target='_blank' style={{ textDecoration: 'none'}}>
                            <Stack direction={'row'} alignItems={'center'} gap={2}>
                                <Icon fontSize={'medium'} sx={{ color: '#fff' }}>call</Icon>
                                <Typography variant={isMobile || isTablet ? 'caption' : "body2"} color="#fff">+62 812-1728-4592 {'(Alex)'}</Typography>
                            </Stack></Link>
                        </Stack>
                    </Stack>
                </Stack>
            </div>
            <Toolbar />
            <div style={{ width: '100%', ...CENTER }}>
                <Typography variant={isMobile || isTablet ? 'body2' : "body1"} color="#fff" textAlign={'center'}>@ 2023 PT. Arenvy Teknologi Marketing. All right reserved.</Typography>
            </div>
        </div>
    )
}

export default Footer;