import { Images } from "../assets/images";

export const CENTER = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const BoxShadow = {
  boxShadow: "-1px 1px 30px 4px rgba(0,0,0,0.05)",
};

export const BackgroundImg = {
  backgroundImage: `url(${Images.background})`,
  backgroundRepeat: 'repeat',
  backgroundSize: 'contain',
}